import { useCallback, useEffect } from 'react';

import { useRouter } from 'next/router';
import { AggregateRating, Thing } from 'schema-dts';

import Seo from '~/src/common/components/Seo';
import { SeoHome } from '~/src/common/components/Seo/SeoHome';
import { useOnSitePickupSlot } from '~/src/common/hooks/useOnSitePickupSlot';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import { IDS, TagManager } from '~/src/common/services/TagManager';
import Toaster from '~/src/common/services/Toaster';
import useGetReviews from '~/src/common/services/Trustpilot/useGetReviews';
import { HomeProduct } from '~/src/common/typings/product';
import { useGetCategories, useHome } from '~/src/queries/api-ecom/generated/api-ecom';
import { CATALOG_QUERY_PARAMS } from '~/src/screens/Home/queries';
import { NextPage } from '~/src/typings/next';

import HomeScreenContent from './HomeScreenContent';
import { ToasterMessage } from './layout';

const HomeScreen: NextPage = () => {
  const { data: homeData, isError, refetch } = useHome(CATALOG_QUERY_PARAMS);
  const { data } = useGetReviews();
  const schemas: Thing[] | undefined = data?.score
    ? [
        {
          '@type': 'Organization',
          'aggregateRating': {
            '@type': 'AggregateRating',
            'ratingValue': String(data.score.trustScore),
            'reviewCount': String(data.numberOfReviews.total),
          } as AggregateRating,
        },
      ]
    : undefined;

  const { data: accueilCatData } = useGetCategories({ tags: 'Home' });

  const accueilCat = accueilCatData?.items?.[0];

  const { query, replace, pathname } = useRouter<{
    login?: boolean;
    signup?: boolean;
    ordercancelled?: string;
    iscartopen?: string;
  }>();
  const { show: showAccountManagementModal } = useNiceModal('account-management-modal');
  const { show: openCartModal } = useNiceModal('cart-modal');

  useEffect(() => {
    if (query?.login) {
      void showAccountManagementModal();
    }
    if (query?.signup) {
      void showAccountManagementModal({ showSignup: true });
    }
  }, [showAccountManagementModal, query?.login, query?.signup]);

  const openCart = useCallback(async () => {
    await replace(pathname, undefined, { shallow: true, scroll: false });

    void openCartModal();
  }, [pathname, openCartModal, replace]);

  useEffect(() => {
    if (query?.ordercancelled) {
      Toaster.notify({
        icon: 'info',
        message: (
          <ToasterMessage>
            <p>{I18n.t('home.update-order.toaster.title')}</p>
            <p>{I18n.t('home.update-order.toaster.content')}</p>
          </ToasterMessage>
        ),
      });
    }

    if (query?.iscartopen || query?.ordercancelled) {
      void openCart();
    }
  }, [query?.ordercancelled, query?.iscartopen, openCart]);

  useEffect(() => {
    const products = homeData?.items?.flatMap(
      homeCategory => homeCategory.products,
    ) as HomeProduct[];

    TagManager.productsView({
      products,
      listName: IDS.promotions,
      forcedCategory: IDS.promotions,
    });
  }, [homeData?.items]);

  // si on arrive avec une URL de PDR sur place
  useOnSitePickupSlot({
    // redirect home et clean les params
    onSuccess: () => {
      void replace(pathname, undefined, { shallow: true, scroll: false });
    },
  });

  const visibleHomeCategory = homeData?.items?.filter(cat => cat.products.length > 0);

  return (
    <>
      {accueilCat ? (
        <SeoHome category={accueilCat} schemas={schemas} />
      ) : (
        <Seo
          metaTitle={I18n.t('common.homepage')}
          metaDescr={I18n.t('home.meta-description')}
          canonicalUrl="/"
          noSuffix
        />
      )}
      <HomeScreenContent
        homeCategories={visibleHomeCategory}
        isError={isError}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises  -- auto-ignored when updating eslint
        onRefresh={refetch}
        homeCatData={accueilCat}
      />
    </>
  );
};

export default HomeScreen;
