import CatalogSwiper from '~/src/common/components/CatalogSwiper';
import { ProductPlaceholder } from '~/src/screens/CatalogContent/ListProductsScreen/layout';

import * as S from './layout';

const HomeSkeleton = () => (
  <>
    <S.SkeletonTitle />
    <S.SkeletonSubTitle />
    <CatalogSwiper>
      {Array.from({ length: 8 }, (_, idx) => (
        <ProductPlaceholder key={idx} />
      ))}
    </CatalogSwiper>
    <S.SkeletonSubTitle />
    <CatalogSwiper>
      {Array.from({ length: 6 }, (_, idx) => (
        <ProductPlaceholder key={idx} />
      ))}
    </CatalogSwiper>
    <S.SkeletonSubTitle />
    <CatalogSwiper>
      {Array.from({ length: 13 }, (_, idx) => (
        <ProductPlaceholder key={idx} />
      ))}
    </CatalogSwiper>
  </>
);

export default HomeSkeleton;
