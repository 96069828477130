import { useEffect, useState } from 'react';

import { getSwiperConfig } from '../utils';

const useScrollable = (nbItems: number) => {
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const { count: nbCardVisible } = getSwiperConfig();

    setIsScrollable(nbItems > nbCardVisible);
  }, [nbItems]);

  return { isScrollable };
};

export default useScrollable;
