import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import useOnUpdateCartDeliverySlotMutationOptions from '~/src/common/hooks/cart/useOnUpdateCartDeliverySlotMutationOptions';
import { useUpdateCartDeliverySlot } from '~/src/common/hooks/cart/useUpdateCartDeliverySlot';
import Tracker, {
  getDeliveryZoneProperties,
  sendShippingSlotValidatedEvent,
  setTimeSlotUserProperties,
} from '~/src/common/services/Tracker';
import { getPreferredTimeSlotTimestamp } from '~/src/common/utils/slots';
import {
  getDeliveryZone,
  getGetDeliveryZoneQueryKey,
  useLinkCartToOrder,
} from '~/src/queries/api-ecom/generated/api-ecom';
import { getNextAvailableSlot, getSlotFromStartHour } from '~/src/screens/Landing/utils';

/**
 * Regarde les params de l'url et redirige si besoin vers la home
 * avec un creneau select auto. pour une cmd sur place
 */
export const useOnSitePickupSlot = ({ onSuccess }: { onSuccess?: () => void }) => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const {
    mutate: updateDeliveryAndRecompute,
    isLoading,
    isSuccess,
  } = useUpdateCartDeliverySlot({
    onSuccess: () => onSuccess?.(),
  });

  const { mutate: linkCartToOrder } = useLinkCartToOrder({
    mutation: useOnUpdateCartDeliverySlotMutationOptions({
      onSuccess: () => onSuccess?.(),
    }),
  });

  useEffect(() => {
    const fetchDeliveryZoneAndSelectSlot = async (deliveryZoneId: string) => {
      const deliveryZone = await queryClient.fetchQuery(
        getGetDeliveryZoneQueryKey(deliveryZoneId),
        () => getDeliveryZone(deliveryZoneId),
      );

      Tracker.sendEvent('shipping type validated', {
        'validation mode': 'auto',
        'event source': 'navigation',
        ...getDeliveryZoneProperties(deliveryZone),
      });

      if (deliveryZone.pickupAddress != null && deliveryZone.deliverySlots.length > 0) {
        setTimeSlotUserProperties(deliveryZone.deliverySlots[0]);

        const filteredSlots = getSlotFromStartHour(deliveryZone.deliverySlots, [0, 23]);

        const selectedSlot =
          getNextAvailableSlot(
            filteredSlots,
            getPreferredTimeSlotTimestamp(deliveryZone.preferredTimeSlotSchedule),
          ) || filteredSlots[0];

        sendShippingSlotValidatedEvent({
          timeSlot: selectedSlot,
          deliveryZone: deliveryZone,
          reasonForChange: 'new address',
        });

        if ('initialOrder' in selectedSlot && selectedSlot.initialOrder) {
          // @ts-expect-error TODO
          linkCartToOrder({ data: { orderId: selectedSlot.initialOrder.id as string } });
        } else {
          updateDeliveryAndRecompute({
            data: {
              delivery: {
                note: '',
                address: {
                  formattedAddress: deliveryZone.pickupAddress.formattedAddress,
                  location: deliveryZone.pickupAddress.location,
                  // dumb adresscomponents, pas besoin vu qu'on est en PDR sur place
                  addressComponents: {
                    streetNumber: '-',
                    street: '-',
                    postalCode: '75001', // postalCode regex en backend
                    city: '-',
                    countryCode: 'FR',
                    country: '-',
                  },
                },
              },
              timeSlot: selectedSlot,
            },
          });
        }
      }
    };

    if (router.query.deep_link_value && router.query.deep_link_sub1) {
      if (router.query.deep_link_value === 'retrait') {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
        fetchDeliveryZoneAndSelectSlot(router.query.deep_link_sub1 as string);
      }
    }
  }, [updateDeliveryAndRecompute, queryClient, router, linkCartToOrder]);

  return {
    isLoading,
    isSuccess,
  };
};
