import styled from 'styled-components';

export const SkeletonTitle = styled.div`
  width: 360px;
  height: 32px;
  max-width: 90%;
  margin: 40px auto 24px;
  background: ${({ theme }) => theme.palette.common.PLACEHOLDER};
`;

export const SkeletonSubTitle = styled.div`
  width: 300px;
  height: 24px;
  max-width: 60%;
  margin: 24px 0 8px;
  background: ${({ theme }) => theme.palette.common.PLACEHOLDER};
`;
