import React, { useState, type ReactElement } from 'react';

import ArrowButton from './ArrowButton';
import { DESKTOP_ITEM_GAP } from './config';
import useFirstScrollAnalytics from './hooks/useFirstScrollAnalytics';
import useScrollable from './hooks/useScrollable';
import useScrollPosition from './hooks/useScrollPosition';
import * as S from './layout';
import { getSwiperConfig } from './utils';

interface Props {
  children: ReactElement | ReactElement[];
  onFirstScroll?: () => void;
}

const CatalogSwiper = ({ children, onFirstScroll }: Props) => {
  // Stockage des éléments dans des states pour forcer le render initial
  const [mainContainer, setMainContainer] = useState<HTMLElement | null>(null);
  const [scrollContainer, setScrollContainer] = useState<HTMLElement | null>(null);

  // Information si le scroll est nécessaire
  const nbItems = React.Children.count(children);
  const { isScrollable } = useScrollable(nbItems);

  // Information sur la position actuelle du scroll
  const { hasScrolled, hasFullyScrolled } = useScrollPosition(scrollContainer);

  // Pour notifier le parent lors du tout premier scroll
  useFirstScrollAnalytics(scrollContainer, onFirstScroll);

  // Scroll handler - Vers la droite ou la gauche
  const handleScroll = (direction: 'left' | 'right') => {
    if (!scrollContainer || !mainContainer) return;

    // Configuration du swiper
    const { count: nbCardVisible } = getSwiperConfig();

    // Récupération des dimensions du parent et des enfants
    const visibleContentWidth = mainContainer.getBoundingClientRect().width || 0;
    const cardWidth = (visibleContentWidth - nbCardVisible * DESKTOP_ITEM_GAP) / nbCardVisible;
    const itemWidth = cardWidth + DESKTOP_ITEM_GAP;

    // Informations sur le scroll actuel
    const currentPosition = scrollContainer.scrollLeft;
    const hasScrolledAtLeastOneItem = currentPosition > itemWidth;
    const nbCardsScrolled = hasScrolledAtLeastOneItem ? currentPosition / itemWidth : 0;

    // Le "scroll gap" est le décalage à gérer pour aligner les "items" sur les bords
    const scrollGap = hasScrolledAtLeastOneItem
      ? (nbCardsScrolled - Math.floor(nbCardsScrolled)) * itemWidth
      : currentPosition;

    const leftDirectionScroll =
      scrollGap > 0
        ? // Décalage vers la gauche du nombre de cartes visibles avec les espacements
          // On réduit le scroll du décalage précédemment calculé pour afficher pleinement la carte qui ne l'était que partiellement affiché
          currentPosition - (nbCardVisible - 1) * itemWidth - scrollGap
        : currentPosition - visibleContentWidth;

    const rightDirectionScroll =
      scrollGap > 0
        ? // Décalage vers la droite du nombre de cartes visibles avec les espacements
          // La différence avec le décalage à gauche c'est qu'on doit afficher "l'autre partie de la carte" qui n'est que partiellement affiché
          currentPosition + (nbCardVisible - 1) * itemWidth + (itemWidth - scrollGap)
        : currentPosition + visibleContentWidth;

    scrollContainer.scrollTo({
      left: direction === 'left' ? leftDirectionScroll : rightDirectionScroll,
      behavior: 'smooth',
    });
  };

  return (
    <S.MainContainer ref={setMainContainer}>
      <ArrowButton
        direction="left"
        aria-hidden={!hasScrolled}
        onClick={() => handleScroll('left')}
      />
      <S.ScrollContainer ref={setScrollContainer}>{children}</S.ScrollContainer>
      <ArrowButton
        direction="right"
        aria-hidden={hasFullyScrolled || !isScrollable}
        onClick={() => handleScroll('right')}
      />
    </S.MainContainer>
  );
};

export default CatalogSwiper;
