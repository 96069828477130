import { QueryObserverResult } from '@tanstack/react-query';

import CatalogSwiper from '~/src/common/components/CatalogSwiper';
import CardDispatcher from '~/src/common/components/ProductsGrid/CardDispatcher';
import I18n from '~/src/common/services/I18n';
import Tracker, { CardInfoWithoutPositionAndCountProperties } from '~/src/common/services/Tracker';
import { sendCardEventClick } from '~/src/common/utils/product';
import { Home200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { ProductPlaceholder } from '~/src/screens/CatalogContent/ListProductsScreen/layout';
import { EmptyText } from '~/src/screens/Home/HomeCategory/layout';
import useItemAnalytics from '~/src/screens/Home/HomeCategory/useItemAnalytics';
import useItems from '~/src/screens/Home/HomeCategory/useItems';
import { HomeCategory } from '~/src/screens/Home/types';
import { CatalogItem } from '~/src/typings/products/types';

interface Props {
  subcategoryName: string;
  homeCategory: HomeCategory;
  products?: CatalogItem[];
  placeholders?: string[];
  categoryAnalyticsProperties?: CardInfoWithoutPositionAndCountProperties;
  onRefresh: () => Promise<QueryObserverResult<Home200>>;
}

const HomeCategorySwiper = ({
  homeCategory,
  subcategoryName,
  products,
  placeholders,
  categoryAnalyticsProperties,
  onRefresh,
}: Props) => {
  const items = useItems(products, placeholders);
  const analyticsProperties = useItemAnalytics(items, categoryAnalyticsProperties);

  const onFirstScroll = () => {
    Tracker.sendEvent('scroll horizontal list', {
      'subcategory name': subcategoryName,
    });
  };

  return items.length === 0 ? (
    <EmptyText>{I18n.t('products-grid.empty')}</EmptyText>
  ) : (
    <CatalogSwiper onFirstScroll={onFirstScroll}>
      {items.map(({ product, productId }) =>
        product ? (
          <CardDispatcher
            key={productId}
            product={product}
            eventSource="products list"
            categoryName="Accueil"
            subcategory={homeCategory}
            onRefresh={onRefresh}
            onClick={p => {
              sendCardEventClick(products, p, {
                source: subcategoryName,
                ...categoryAnalyticsProperties,
              });
            }}
            analyticsProperties={analyticsProperties}
          />
        ) : (
          <ProductPlaceholder id={productId} key={productId} />
        ),
      )}
    </CatalogSwiper>
  );
};

export default HomeCategorySwiper;
