import { useQueryClient } from '@tanstack/react-query';
import { LDContext } from 'launchdarkly-js-sdk-common';

import { useApiEcomClientError } from '~/src/common/services/error-handler/error-handler';
import { updateFeatureFlagContext } from '~/src/common/services/FeatureFlag/client';
import I18n from '~/src/common/services/I18n';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Toaster from '~/src/common/services/Toaster';
import { ToasterTextUpdateDeliverySlot } from '~/src/common/services/Toaster/Toast/layout';
import Tracker from '~/src/common/services/Tracker';
import { getDeliveryDateWithTimeslotInterval } from '~/src/common/utils/date';
import { escapeRegExp } from '~/src/common/utils/text';
import {
  getGetCartQueryKey,
  getGetCategoryQueryKey,
} from '~/src/queries/api-ecom/generated/api-ecom';
import {
  getGetAccountProductsQueryKey,
  getGetApiAccountCouponsQueryKey,
  getGetCategoriesQueryKey,
  getGetList2QueryKey,
  getGetProductIdsQueryKey,
  getGetSearchPopularQueryKey,
  getGetSearchSuggestionsQueryKey,
  getHomeQueryKey,
  getNavigationQueryKey,
  getProductsByCategoryQueryKey,
  getRecompute2QueryKey,
  getSearchItemsQueryKey,
} from '~/src/queries/api-ecom/generated/api-ecom';
import { PatchCartDelivery2200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { ApiEcomError } from '~/src/queries/services/client';
import { isDelivery } from '~/src/screens/App/CatalogLayout/utils';

// La liste des queries à invalider en cas de changement de créneau.
// Si une des clés nécessite un argument, utiliser le placeholder.
const INVALIDATION_PLACEHOLDER = '♻️';
const INVALIDATION_PATTERN_LIST = [
  getGetCartQueryKey(), // Panier
  getRecompute2QueryKey(), // Recompute2
  getGetList2QueryKey(INVALIDATION_PLACEHOLDER), // Favoris
  getHomeQueryKey(), // Produits de la home
  getGetCategoryQueryKey(INVALIDATION_PLACEHOLDER), // Catégorie
  getGetCategoriesQueryKey(), // Les catégories
  getGetProductIdsQueryKey(INVALIDATION_PLACEHOLDER), // IDs des produits d'une catégorie
  getProductsByCategoryQueryKey(INVALIDATION_PLACEHOLDER), // Produits d'une catégorie
  getGetApiAccountCouponsQueryKey(), // Coupons
  getGetSearchPopularQueryKey(), // Mots clés populaire
  getGetSearchSuggestionsQueryKey({ query: INVALIDATION_PLACEHOLDER }), // Suggestions de recherche
  // @ts-expect-error -- Le placeholder n'est pas compatible avec le typage de la
  // fonction mais est nécessaire pour matcher toutes les routes correspondantes
  getSearchItemsQueryKey({ text: INVALIDATION_PLACEHOLDER }), // Résultats de recherche
  getNavigationQueryKey(), // Navigation
  getGetAccountProductsQueryKey(), // Mes produits
];

// Les patterns ont convertis en une liste de RegExp au format string qui
// sont ensuite combinés pour donner l'expression réguliere d'invalidation
const INVALIDATION_REGEXP_LIST = INVALIDATION_PATTERN_LIST.map(key => {
  const clean = escapeRegExp(String([key].flat().shift()));
  return clean.replace(new RegExp(INVALIDATION_PLACEHOLDER, 'g'), '.*');
});
const INVALIDATION_REGEXP = new RegExp(`^(${INVALIDATION_REGEXP_LIST.join('|')})$`);

export const shouldInvalidateQuery = (queryKey: unknown) => {
  const path = String([queryKey].flat().shift());
  return INVALIDATION_REGEXP.test(path);
};

interface Payload {
  onSuccess: () => void;
}

const useOnUpdateCartDeliverySlotMutationOptions = ({ onSuccess }: Payload) => {
  const queryClient = useQueryClient();
  const handleError = useApiEcomClientError();
  const deliverySelectSlotModal = useNiceModal('delivery-select-slot-modal');

  return {
    onSuccess: (cart: PatchCartDelivery2200) => {
      queryClient.setQueryData(getGetCartQueryKey(), cart);

      // Invalidation des queries, RQ se chargera de refetch si nécessaire
      void queryClient.invalidateQueries({
        predicate: ({ queryKey }) => shouldInvalidateQuery(queryKey),
      });

      // optimisation pour avoir le panier en mode complétion ou non dès l'ouverture de celui-ci
      // les infos utilisé sur le cart proviennent de la query recompute2
      void queryClient.fetchQuery(getRecompute2QueryKey());

      Tracker.sendEvent('shipping slot toaster viewed');

      const {
        delivery: { deliveryZone, timeSlot, shop },
      } = cart;

      const translationKey = isDelivery(cart.delivery) ? 'delivery' : 'pickup';

      Toaster.notify({
        message: (
          <ToasterTextUpdateDeliverySlot>
            {I18n.translate({
              value: `delivery-address-modal.${translationKey}-notification`,
              slot: getDeliveryDateWithTimeslotInterval(timeSlot),
            })}
            <p>{I18n.t('delivery-address-modal.prices-update-notification')}</p>
          </ToasterTextUpdateDeliverySlot>
        ),
        icon: 'valid-big',

        actionButtonLabel: I18n.t('common.modify'),
        onActionButtonClick: () => {
          Tracker.sendEvent('shipping slot toaster click');

          void deliverySelectSlotModal.show();
        },
      });

      void updateFeatureFlagContext((currentContext: LDContext) => ({
        ...currentContext,
        delivery: {
          key: timeSlot.id,
          shop: shop?.id,
          deliveryZone: deliveryZone?.id,
        },
      }));

      onSuccess();
    },
    onError: (error: unknown) => {
      handleError(error as ApiEcomError);
    },
  };
};

export default useOnUpdateCartDeliverySlotMutationOptions;
