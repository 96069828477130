import { Thing } from 'schema-dts';

import { GetCategories200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

import { IMAGE_TRANSFORM_CATEGORY } from './constants';
import Seo from './Seo';
import { getSeoImage } from './utils';

type Props = {
  category: GetCategories200['items'][number];
  schemas?: Thing[];
};

export const SeoHome = ({ category, schemas }: Props) => {
  const { seo, slug, images } = category;
  const { title, description, indexation, canonical } = seo;
  const image = getSeoImage(images?.[0]?.url, IMAGE_TRANSFORM_CATEGORY);

  return (
    <Seo
      title={title}
      image={image}
      noIndex={!indexation}
      metaDescr={description}
      canonicalUrl={canonical ?? `/categorie/${slug}`}
      noSuffix
      schemas={schemas}
    />
  );
};
