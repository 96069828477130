import useOnUpdateCartDeliverySlotMutationOptions from '~/src/common/hooks/cart/useOnUpdateCartDeliverySlotMutationOptions';
import { usePatchCartDelivery2 } from '~/src/queries/api-ecom/generated/api-ecom';

interface UpdateCartDeliverySlotPayload {
  onSuccess: () => void;
}

export const useUpdateCartDeliverySlot = ({ onSuccess }: UpdateCartDeliverySlotPayload) => {
  return usePatchCartDelivery2({
    mutation: useOnUpdateCartDeliverySlotMutationOptions({ onSuccess }),
  });
};
